/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */
import axios from "axios";

//const url = 'https://api.word.firmamex.com';
//const url = 'https://localhost:44302';
const url = process.env.REACT_APP_BACKEND;

function backend(path) {
    return `${url}${path}`
}

function buildGet() {
    let source = axios.CancelToken.source();
    return {
        source,
        get: function(path) {
            return axios.get(backend(path), {
                cancelToken: source.token
            });
        }
    }
}

function httpGet(path) {
    console.log('get: ' + path)    
    return axios.get(backend(path));
}

async function httpPost(path, body, contentType) {
    console.log('post: ' + path)
    return axios.post(backend(path), body, { headers: {
            'Access-Control-Allow-Credentials': 'true',
            'Content-Type': contentType,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
            'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers'
        }
    });
}

export  {
    backend,
    httpGet,
    httpPost,
    buildGet
}