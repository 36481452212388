/* eslint-disable prettier/prettier */
/* eslint-disable no-undef */
import * as React from "react";
import { DefaultButton, ButtonType } from "office-ui-fabric-react";
import Header from "./Header";
//import MinimalHeader from "./MinimalHeader";
import Progress from "./Progress";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
  Collapse,
  Card,
  CardText,
  //CardBody,
  //Input,
  FormGroup,
  Row,
  Col,
  Container
} from "reactstrap";
import { httpPost } from "../util/Config";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { registerLocale } from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
//import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
registerLocale("es", es);
import i18next from "i18next";
import { BiPlus, BiMinus, BiWorld } from "react-icons/bi";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
//const Welcome = require("../images/welcome.svg") as string;
import Welcome from "../images/welcome.svg";
import Check from "../images/flujo-iniciado.svg";
import Working from "../images/trabajando.svg";

let esJson = require("./es.json");
let enJson = require("./en.json");

export interface AppProps {
  title: string;
  isOfficeInitialized: boolean;
}

export interface Sticker {
  id: number;
  type: string;
  data: string;
}

export interface AppState {
  loggedIn: boolean;
  jwtInput: string;
  jwt: string;
  userInfo: {
    userName: string;
    userEmail: string;
    clientName: string;
  };
  unformattedStickerList: Sticker[];
  stickerList: Array<any>;
  fullStickerList: Array<Sticker>;
  QRList: Array<any>;
  QRpaginas: string;
  documentResult: any;
  url: string;
  message: string;
  notifyWhenCompleted: boolean;
  attachToEmail: boolean;
  flowExpires: boolean;
  expirationDate: string;
  expirationDateFormat: string;
  remindEvery: string;
  language: string;
  emailToAdd: string;
  unformattedEmailList: Array<string>;
  emailList: Array<any>;
  errorMessage: string;
  reportCompleted: boolean;
  allowUpload: boolean;
  credentialsOpen: boolean;
  linkCopied: boolean;
  working: boolean;
  //autoLogin: boolean;
  flowConfOpen: boolean;
  instructionsOpen: boolean;
  reporteOpen: boolean;
  reporte: Array<any>;
  languageApp: string;
  finalList: Array<any>;
  languageToggle: boolean;
  logOutWarning: boolean;
  welcome :boolean;
}

/*const imageLabels = {
    "desc": 'Descriptive',
    "stroke": 'Stroke',
    "hash": "Hash",
    "stroke+desc": "StrokeDescriptive",
    'stroke_only': 'StrokeOnly',
    'name': 'Name'
}*/

i18next
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "es",
    debug: true,
    resources: {
      en: {
        translation: enJson
      },
      es: {
        translation: esJson
      }
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });

/*const SortableItem = SortableElement(({ value, key }) => (
  <li key={key} className="SortableItem">
    {value}
  </li>
));*/

/*const SortableList = SortableContainer(({ items }) => {
  return (
    <ul className="SortableList">
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} value={value} />
      ))}
    </ul>
  );
});*/

export default class App extends React.Component<AppProps, AppState> {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loggedIn: false,
      jwtInput: "",
      jwt: "",
      //listItems: []
      userInfo: {
        userName: "",
        userEmail: "",
        clientName: "",
      },
      stickerList: [],
      unformattedStickerList: [],
      fullStickerList: [],
      QRList: [],
      QRpaginas: "",
      documentResult: null,
      url: "",
      message: "",
      notifyWhenCompleted: true,
      attachToEmail: true,
      flowExpires: false,
      expirationDate: moment().locale("es").format("L"),
      expirationDateFormat: "",
      remindEvery: "1d",
      language: "es",
      emailToAdd: "",
      emailList: [],
      unformattedEmailList: [],
      errorMessage: "",
      reportCompleted: false,
      allowUpload: false,
      credentialsOpen: true,
      linkCopied: false,
      working: false,
      //autoLogin: true,
      flowConfOpen: true,
      instructionsOpen: false,
      reporteOpen: true,
      reporte: [],
      languageApp: "es",
      finalList: [],
      languageToggle: false,
      logOutWarning: false,
      welcome: true
    };
    this.startProcess = this.startProcess.bind(this);
    this.customInputSwitched = this.customInputSwitched.bind(this);
  }

  componentDidMount() {
    moment.locale("es");
    //document.getElementById("logInInput").focus();

    let languageLocal = localStorage.getItem("language");

    let substringLang = "";

    if (languageLocal) {
      substringLang = languageLocal;
    } else {
      let language = window.navigator.language;
      substringLang = language.substring(2, 0);
    }

    this.setState({
      ...this.state,
      languageApp: substringLang,
    });

    /*let jwtLocal = localStorage.getItem("jwt");

    if (jwtLocal) {
      this.getJWTInfo(jwtLocal, substringLang);
    } else {
      this.setState({
        ...this.state,
        jwtInput: "",
        userInfo: {
          userName: "",
          userEmail: "",
          clientName: "",
        },
        autoLogin: false,
        languageApp: substringLang,
      });
    }*/
  }

  /*getJWTInfo = async (jwtAuto, language) => {
    this.setState({ ...this.state, working: true, languageApp: language });

    let jwtCheck = "";
    !jwtAuto ? (jwtCheck = this.state.jwtInput) : (jwtCheck = jwtAuto);

    if (jwtCheck) {
      let LoginData = {
        jwt: jwtCheck,
      };

      let jwtInfo = null;

      try {
        jwtInfo = await httpPost(`/WordAddin/getJwtInfo/`, LoginData, "application/json");
      } catch (error) {
        console.log(error);
      }

      if (jwtInfo) {
        if (!jwtInfo.data.error && jwtInfo.data.userName && jwtInfo.data.userEmail && jwtInfo.data.clientName) {
          let userName = jwtInfo.data.userName;
          let userEmail = jwtInfo.data.userEmail;
          let clientName = jwtInfo.data.clientName;

          this.setState({
            ...this.state,
            loggedIn: true,
            jwtInput: "",
            jwt: jwtCheck,
            userInfo: {
              userName: userName,
              userEmail: userEmail,
              clientName: clientName,
            },
            errorMessage: "",
            working: false,
            //autoLogin: false,
          });
          localStorage.setItem("jwt", jwtCheck);
        } else {
          if (jwtInfo.data.error) {
            this.logOutWithError(jwtInfo.data.error);
          }
          window.scrollTo(0, 0);
        }
      } else {
        this.resetAddin("errorTimeout");
        window.scrollTo(0, 0);
      }
    } else {
      this.setState({ ...this.state, loggedIn: false, errorMessage: "noJWT", working: false, autoLogin: false });
      window.scrollTo(0, 0);
    }
  };*/

  resetAddin(error) {
    this.setState({
      ...this.state,
      loggedIn: false,
      jwtInput: "",
      jwt: "",
      userInfo: {
        userName: "",
        userEmail: "",
        clientName: "",
      },
      stickerList: [],
      unformattedStickerList: [],
      fullStickerList: [],
      QRList: [],
      QRpaginas: "",
      documentResult: null,
      url: "",
      message: "",
      notifyWhenCompleted: true,
      attachToEmail: true,
      flowExpires: false,
      expirationDate: moment().locale("es").format("L"),
      expirationDateFormat: "",
      remindEvery: "1d",
      language: "es",
      emailToAdd: "",
      emailList: [],
      unformattedEmailList: [],
      errorMessage: error,
      reportCompleted: false,
      allowUpload: false,
      credentialsOpen: true,
      linkCopied: false,
      working: false,
      //autoLogin: false,
      flowConfOpen: false,
      instructionsOpen: false,
      reporteOpen: true,
      reporte: [],
      languageToggle: false,
      logOutWarning: false,
    });
  }

  documentName(): Promise<any> {
    return new Promise((resolve, reject) => {
      Office.context.document.getFilePropertiesAsync(function (documentResult) {
        const contextInfo = Office.context.diagnostics;
        let fileNameEx = documentResult.value.url;
        let fileNameStart = fileNameEx.slice(0, 8);

        if (fileNameEx) {
          let fileNameSplit = [];
          let secondSplit = [];
          if(contextInfo.platform === Office.PlatformType.Mac || contextInfo.platform === Office.PlatformType.iOS){

            if(fileNameStart === "https://") { 
              // The name is an URL (more than likely a OneDrive document)
              fileNameEx = fileNameEx.replaceAll("//", "/");
            } 

            fileNameSplit = fileNameEx.split("/");
          } else {
            if(fileNameStart === "https://") { 
              // The name is an URL (more than likely a OneDrive document)
              fileNameEx = fileNameEx.replaceAll("//", "\\");
              fileNameEx = fileNameEx.replaceAll("/", "\\");
            } 
              
            fileNameSplit = fileNameEx.split("\\");
          }
          
          secondSplit = fileNameSplit[fileNameSplit.length - 1].split(".");
  
          if (secondSplit[0] === "unsavedDocument") {
            reject("not_saved");
          } else if (secondSplit[0] !== "") {
            resolve(secondSplit[0]);
          } else {
            reject("error_name");
          }
          //fileNameEx = fileNameEx.replace("\\", "/");

        } else {
          reject("not_saved");
        }
      });
    });
  }

  document(): Promise<any> {
    return new Promise((resolve, reject) => {
      Office.context.document.getFileAsync(Office.FileType.Pdf, function (result) {
        if (result) {
          resolve(result.value);
        } else {
          reject("error obteniendo documento");
        }
      });
    });
  }

  getAllDocumentSlices(file): Promise<any> {
    return new Promise((resolve, reject) => {
      let sliceCount = file.sliceCount;
      let sliceIndex = 0;
      let docData = [];

      let getSlice = function () {
        file.getSliceAsync(sliceIndex, function (asyncResult) {
          if (asyncResult.status === "succeeded") {
            docData = docData.concat(asyncResult.value.data);
            sliceIndex++;
            if (sliceIndex == sliceCount) {
              file.closeAsync();
              resolve(docData);
            } else {
              getSlice();
            }
          } else {
            file.closeAsync();
            document.getElementById("log").textContent = JSON.stringify(asyncResult);
            reject("error_document_Data");
          }
        });
      };
      getSlice();
    });
  }

  changeLanguage = async (lang) => {
    i18next.changeLanguage(lang);
    localStorage.setItem("language", lang);
    this.setState({...this.state, 
      reporte: this.state.stickerList.map((data, i) => {
        return ( <Card key={i + 1} style={{ paddingTop: "5px", paddingBottom: "5px" }} body className="text-center">
          <CardText>
            <b>{data.data}</b>
            <br />
            {data.dataType} -
            {data.imageType !== "desc" ? (
              <React.Fragment>
                <span style={{ marginLeft: "5px" }}>
                  {i18next.t(data.imageType)} - {i18next.t("page")}: {data.page}
                </span>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <span style={{ marginLeft: "5px" }}>
                  {i18next.t("Descriptive")} - {i18next.t("page")}: {data.page}
                </span>
              </React.Fragment>
            )}
          </CardText>
        </Card> )
      }),
     languageApp: lang});
  };

  
  restart = () => {
    this.setState({
      ...this.state,
      stickerList: [],
      unformattedStickerList: [],
      fullStickerList: [],
      QRList: [],
      QRpaginas: "",
      documentResult: null,
      url: "",
      message: "",
      language: "es",
      unformattedEmailList: [],
      reportCompleted: false,
      allowUpload: false,
      linkCopied: false,
      working: false,
      reporteOpen: true,
      reporte: [],
      errorMessage: "",
      languageToggle: false,
      welcome: false
    });
  }


  startProcess = async (complete) => {
    if (complete) {
      this.setState({ ...this.state, working: true });
    } else {
      this.setState({
        ...this.state,
        stickerList: [],
        unformattedStickerList: [],
        fullStickerList: [],
        QRList: [],
        QRpaginas: "",
        documentResult: null,
        url: "",
        message: "",
        notifyWhenCompleted: true,
        attachToEmail: true,
        flowExpires: false,
        expirationDate: moment().locale("es").format("L"),
        expirationDateFormat: "",
        remindEvery: "1d",
        language: "es",
        emailToAdd: "",
        emailList: [],
        unformattedEmailList: [],
        reportCompleted: false,
        allowUpload: false,
        credentialsOpen: true,
        linkCopied: false,
        working: true,
        flowConfOpen: false,
        instructionsOpen: false,
        reporteOpen: true,
        reporte: [],
        errorMessage: "",
        languageToggle: false,
        logOutWarning: false,
        welcome: false
      });
    }

    try {
      let documentName = await this.documentName();
      let document = await this.document();
      let documentData = await this.getAllDocumentSlices(document);

      if (documentData) {
        this.onGetAllSlicesSucceeded(documentData, documentName, complete);
      }
    } catch (error) {
      console.log(error);

      let errorLabel = "";

      if (error === "not_saved") {
        errorLabel = "documentNotSaved";
      } else if (error === "error_name") {
        errorLabel = "errorGettingName";
      } else if(error === "error_document_Data") {
        errorLabel = "errorDocumentData";
      } else {
        errorLabel = "errorGeneral";
      }

      this.setState({ ...this.state, errorMessage: errorLabel, url: "", linkCopied: false, working: false });
    }
  };

  encodeBase64(docData) {
    let s = "";
    for (const element of docData) {
      s += String.fromCharCode(element);
    }
    return window.btoa(s);
  }

  onGetAllSlicesSucceeded = async (docData, fileName, complete) => {
    if (complete) {
      let base64 = this.encodeBase64(docData);

      let DocumentData = {
        base64Document: base64,
        documentName: fileName,
      };

      let postDocument = null;

      try {
        postDocument = await httpPost(`/WordAddin/uploadDocument/`, DocumentData, "application/pdf");
      } catch (error) {
        console.log(error);
      }

      if (postDocument) {
        if (!postDocument.data.error) {
          this.setState({
            ...this.state,
            errorMessage: "",
            url: postDocument.data.document_url,
            linkCopied: false,
            working: false,
          });
        } else {
          if (postDocument.data.error === "El JWT no esta activo") {
            this.logOutWithError("jwtRevoked");
          } else if (
            postDocument.data.error ===
            "workflow: to start a workflow, every sticker must have an email or phone assigned"
          ) {
            this.setState({
              ...this.state,
              errorMessage: "flowErrorEmail",
              url: "",
              linkCopied: false,
              working: false,
            });
          } else if (postDocument.data.error) {
            this.setState({
              ...this.state,
              errorMessage: postDocument.data.error,
              url: "",
              linkCopied: false,
              working: false,
            });
          }

          window.scrollTo(0, 0);
        }
      } else {
        this.setState({ ...this.state, errorMessage: "errorTimeout", url: "", linkCopied: false, working: false });

        window.scrollTo(0, 0);
      }
    } else {
      let base64v = this.encodeBase64(docData);

      let ReportMeta = {
        documentData: base64v,
        fileName: fileName
        //jwt: "Bearer " + this.state.jwt,
      };

      let generateReport = null;

      try {
        generateReport = await httpPost(`/WordAddin/generateReport`, ReportMeta, "application/json");
      } catch (error) {
        console.log(error);
      }

      if (generateReport) {
        if (!generateReport.data.error) {
          let finalStickerList = [];
          let stickerData = [];
          let finalQRlist = [];

          let stickerId = 1;
          let daysQR = [];

          for (const property in generateReport.data) {
            let sticker = generateReport.data[property];

            if (sticker.dataType === "RFC" || sticker.dataType === "EMAIL" || sticker.dataType === "PHONE") {
              finalStickerList.push(sticker);

              if(sticker.stickerType) {
                sticker.stickerType = "line";
              } else {
                sticker.stickerType = "non";
              }

              if (sticker.data) {
                stickerData.push({                 
                    id: stickerId,
                    authority: sticker.authority,
                    stickerType: sticker.stickerType,
                    dataType: sticker.dataType,
                    data: sticker.data,
                    imageType: sticker.imageType.toLowerCase(),
                    email: sticker.email,
                    page: sticker.page,
                    rect: {
                      lx: sticker.lx,
                      ly: sticker.ly,
                      tx: sticker.tx,
                      ty: sticker.ty
                    }
                });
              }
              stickerId = stickerId + 1;
            } else {
              finalQRlist.push(sticker);
              daysQR.push(sticker.page);
            }
          }

          daysQR = daysQR.filter((v, cd, a) => a.indexOf(v) === cd);
          let stringDays = "";

          let daysLenght = daysQR.length;
          for (let ed = 0; ed < daysLenght; ed++) {
            let concatDay = "";
            let dayFix = daysQR[ed] + 1;
            if (ed !== daysLenght - 1) {
              concatDay = dayFix + ", ";
            } else if (ed === daysLenght - 1) {
              concatDay = dayFix;
            }
            stringDays += concatDay;
          }

          let allowUploadCheck = false;
          stickerData.length > 0 ? (allowUploadCheck = true) : (allowUploadCheck = false);
          stickerData.sort((a, b) => a.page - b.page);

          let purgedStickerList = stickerData;
          purgedStickerList = purgedStickerList.filter((v, i, a) => a.findIndex((t) => t.data === v.data) === i);


          this.setState({
            ...this.state,
            QRList: finalQRlist,
            reporte: stickerData.map((data, i) => {
              return ( <Card key={i + 1} style={{ paddingTop: "5px", paddingBottom: "5px" }} body className="text-center">
                <CardText>
                  <b>{data.data}</b>
                  <br />
                  {data.dataType} -
                  {data.imageType !== "desc" ? (
                    <React.Fragment>
                      <span style={{ marginLeft: "5px" }}>
                        {i18next.t(data.imageType)} - {i18next.t("page")}: {data.page}
                      </span>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <span style={{ marginLeft: "5px" }}>
                        {i18next.t("Descriptive")} - {i18next.t("page")}: {data.page}
                      </span>
                    </React.Fragment>
                  )}
                </CardText>
              </Card> )
            }),
            stickerList: stickerData,
            QRpaginas: stringDays,
            unformattedStickerList: purgedStickerList,
            reportCompleted: true,
            allowUpload: allowUploadCheck,
            errorMessage: "",
            url: "",
            linkCopied: false,
            finalList: finalStickerList,
            working: false,
          });
        } else {
          if (generateReport.data.error === "El JWT no esta activo") {
            this.logOutWithError("jwtRevoked");
          } else if (generateReport.data.error) {
            this.setState({
              ...this.state,
              errorMessage: generateReport.data.error,
              url: "",
              linkCopied: false,
              working: false,
            });
          }

          window.scrollTo(0, 0);
        }
      } else {
        this.setState({ ...this.state, errorMessage: "errorTimeout", url: "", linkCopied: false, working: false });

        window.scrollTo(0, 0);
      }
    }
  };

  handleRLDDChange = (newItems) => {
    this.setState({ ...this.state, unformattedStickerList: newItems });
  };

  removeEmail(email) {
    let array = [...this.state.unformattedEmailList];
    let arrayList = [...this.state.emailList];
    let indexOf = array.findIndex((e) => e === email);

    if (indexOf !== -1) {
      array.splice(indexOf, 1);
      arrayList.splice(indexOf, 1);
      this.setState({ ...this.state, emailList: arrayList, unformattedEmailList: array });
    }
  }

  addEmail() {
    let unformatted = [...this.state.unformattedEmailList];
    if (
      this.state.emailToAdd &&
      this.validateEmail(this.state.emailToAdd) &&
      !unformatted.includes(this.state.emailToAdd)
    ) {
      unformatted.push(this.state.emailToAdd);

      this.setState({
        ...this.state,
        emailList: unformatted.map((data, i) => (
          <tr key={i}>
            <td style={{ width: "90%", overflowWrap: "anywhere" }}>
              <span key={i + 1}>{data}</span>
            </td>
            <td>
              <span onClick={() => this.removeEmail(data)}>
                <b>X</b>
              </span>
            </td>
          </tr>
        )),
        unformattedEmailList: unformatted,
        emailToAdd: "",
      });
    }
  }

  handleChange = (e) => {
    this.setState({ ...this.state, jwtInput: e.target.value });
  };

  handleSpecialChange = (e, name) => {
    this.setState({ ...this.state, [name]: e.target.value });
  };

  handleDateChange = (e) => {
    let datePick = e.target.value;
    let formatString = moment(datePick).locale("es").format("L");
    let format = moment(datePick);
    let formatdd = moment();

    if (format.diff(formatdd) > 0) {
      this.setState({ expirationDate: datePick, expirationDateFormat: formatString });
    } else {
      this.setState({ expirationDate: "", expirationDateFormat: "" });
    }
  };

  customInputSwitched(name, e) {
    this.setState({ ...this.state, [name]: e.target.checked });
  }

  customDateInputSwitched(name, e) {
    this.setState({ ...this.state, [name]: e.target.checked, expirationDateFormat: "" });
  }

  validateEmail(email) {
    const regexp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexp.test(email);
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ stickerList }) => ({
      stickerList: arrayMoveImmutable(stickerList, oldIndex, newIndex),
    }));
  };

  logOutWithError(error) {
    this.resetAddin(error); // resets everything
    localStorage.removeItem("jwt");
  }

  logOut() {
    if (this.state.logOutWarning) {
      this.resetAddin(""); // resets everything
      localStorage.removeItem("jwt");
    } else {
      this.setState({ ...this.state, logOutWarning: true });
    }
  }

  render() {
    const { title, isOfficeInitialized } = this.props;

    if (!isOfficeInitialized) {
      return (
        <Progress title={title} logo="assets/ic_launcher32.png" message="Loading..." />
      );
    }

    //const toggle = () => this.setState({ ...this.state, credentialsOpen: !this.state.credentialsOpen });
    //const toggleInstructions = () => this.setState({ ...this.state, instructionsOpen: !this.state.instructionsOpen });
    const toggleReporte = () => this.setState({ ...this.state, reporteOpen: !this.state.reporteOpen });
    //const toggleConf = () => this.setState({ ...this.state, flowConfOpen: !this.state.flowConfOpen });

    const toggle2 = () => this.setState({ ...this.state, languageToggle: !this.state.languageToggle });

    return (
      <div className="ms-welcome">
        <Dropdown isOpen={this.state.languageToggle} toggle={toggle2}>
          <DropdownToggle
            tag="span"
            data-toggle="dropdown"
            aria-expanded={this.state.languageToggle}
            style={{ position: "absolute", top: "10px", right: "10px" }}
            nav
          >
            <BiWorld
              size="1.7em"
              style={{ color: "#0966c9", position: "absolute", top: "5px", right: "5px", cursor: "pointer" }}
            />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => this.changeLanguage("es")}>Español</DropdownItem>
            <DropdownItem onClick={() => this.changeLanguage("en")}>English</DropdownItem>
          </DropdownMenu>
        </Dropdown>
        {
          !this.state.reportCompleted && this.state.welcome ? <React.Fragment>
            <Header
              logo="assets/ic_launcher96.png"
              title={this.props.title}
              firstMessage="Firmamex"
              secondMessage="Word Add-in"
            />
          </React.Fragment> : <React.Fragment><br/></React.Fragment>
        }
        { this.state.errorMessage ? (
          <React.Fragment>
            <br />
            <span style={{ color: "red", fontWeight: "bold" }}>{i18next.t(this.state.errorMessage)}</span>
          </React.Fragment>
        ) : null}
        <br />
        {
          !this.state.reportCompleted ? <React.Fragment>
            <span style={{ display: "inline !important" }}>
              {i18next.t("checkDocumentation")}{" "}
              <a target="_blank" href={process.env.REACT_APP_DOCUMENTATION} rel="noreferrer">
                {i18next.t("documentation")}
              </a>
              .
            </span>
            <br />
            <span style={{ display: "inline !important" }}>
              {i18next.t("loginNotice")}{" "}
              <a target="_blank" href={process.env.REACT_APP_FIRMAMEX} rel="noreferrer">
                Firmamex
              </a>
              .
            </span>
          </React.Fragment> : null
        }
        <div style={{textAlign: "center", marginTop: "25px"}}>
          {
            !this.state.reportCompleted && !this.state.working ? <React.Fragment> 
              <Welcome />
            </React.Fragment> : null
          }
          {
            this.state.working ? <React.Fragment>
              <Working />
            </React.Fragment> : null
          }
          {
            this.state.reportCompleted && !this.state.working ? <React.Fragment> 
              <Check />
            </React.Fragment> : null
          }
        </div>
        {
          !this.state.reportCompleted && !this.state.working ? 
          <React.Fragment>
            <DefaultButton
                style={{
                  backgroundColor: !this.state.working ? "#0966c9" : "#DCDCDC",
                  color: !this.state.working ? "white" : "gray",
                  borderRadius: "10px",
                  height: "auto",
                  padding: "5px"
                }}
                disabled={this.state.working}
                className="ms-welcome__action"
                buttonType={ButtonType.hero}
                onClick={() => this.startProcess(false)}
              >
              <b>{i18next.t("sendToFirmamex")}</b>
            </DefaultButton>
            <br />
            {i18next.t("sendNotice")}
          </React.Fragment> : null }
        {
          this.state.reportCompleted && !this.state.working ? <React.Fragment>
            <DefaultButton
                style={{
                  backgroundColor: !this.state.working ? "#0966c9" : "#DCDCDC",
                  color: !this.state.working ? "white" : "gray",
                  borderRadius: "10px",
                  height: "auto",
                  padding: "5px"
                }}
                disabled={this.state.working}
                className="ms-welcome__action"
                buttonType={ButtonType.hero}
                onClick={() => this.restart()}
              >
              <b>{i18next.t("restart")}</b>
            </DefaultButton>
          </React.Fragment> : null 
        }
        { 
          this.state.working ? (
          <React.Fragment>
            <br />
            <div style={{ marginLeft: "auto", marginRight: "auto" }}>
              <Spinner color="primary" />
            </div>
            <br />
          </React.Fragment>
        ) : null}
        { this.state.reportCompleted && this.state.url && !this.state.working ? (
          <React.Fragment>
            <Container>
              <br />
              {i18next.t("processComplete")}
              <Row style={{ marginLeft: "-10px" }}>
                <Col>
                  <FormGroup>
                    <CopyToClipboard
                      text={this.state.url}
                      onCopy={() => this.setState({ ...this.state, linkCopied: true })}
                    >
                      <DefaultButton
                        style={{ backgroundColor: "#0966c9",
                          color: "white", 
                          borderRadius: "10px",
                          height: "auto",
                          padding: "5px"
                        }}
                        className="ms-welcome__action"
                        disabled={!this.state.url}
                        buttonType={ButtonType.hero}
                      >
                        {i18next.t("copyLink")}
                      </DefaultButton>
                    </CopyToClipboard>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <DefaultButton
                      style={{ backgroundColor: "#0966c9", 
                        color: "white",
                        borderRadius: "10px",
                        height: "auto",
                        padding: "5px"  
                      }}
                      className="ms-welcome__action"
                      disabled={!this.state.url}
                      buttonType={ButtonType.hero}
                      onClick={() => window.open(this.state.url, "_blank")}
                    >
                      {i18next.t("seeDocument")}
                    </DefaultButton>
                  </FormGroup>
                </Col>
              </Row>
            </Container>
            {this.state.linkCopied ? (
              <React.Fragment>{i18next.t("copySuccesful")}</React.Fragment>
            ) : null}
          </React.Fragment>
        ) : null}
        { this.state.reportCompleted && !this.state.url && !this.state.working ? (
          <React.Fragment>
            <br />
            <DefaultButton
              style={{ backgroundColor: "#0966c9", fontWeight: 'bold', color: "white", borderRadius: "10px" }}
              buttonType={ButtonType.hero}
              onClick={() => toggleReporte()}
            >
              {i18next.t("signersReport")}{" "}
              <span style={{ marginLeft: "5px" }}>
                {!this.state.reporteOpen ? (
                  <React.Fragment>
                    <BiPlus />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <BiMinus />
                  </React.Fragment>
                )}
              </span>
            </DefaultButton>
            <Collapse isOpen={this.state.reporteOpen}>
              <Card body className="text-center">
                <CardText>
                  {i18next.t("stickersFound")} <b>{this.state.reporte.length}</b>
                </CardText>
              </Card>              
              {
                this.state.stickerList.map((data, i) => {
                  return (<Card key={i + 1} style={{ paddingTop: "5px", paddingBottom: "5px" }} body className="text-center">
                    <CardText>
                      <b>{data.data}</b>
                      <br />
                      {data.dataType} -
                      {data.imageType !== "desc" ? (
                        <React.Fragment>
                          <span style={{ marginLeft: "5px" }}>
                            {i18next.t(data.imageType)} - {i18next.t("page")}: {data.page}
                          </span>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <span style={{ marginLeft: "5px" }}>
                            {i18next.t("Descriptive")} - {i18next.t("page")}: {data.page}
                          </span>
                        </React.Fragment>
                      )}
                    </CardText>
                  </Card> 
                  ) 
                })
              }
            </Collapse>
            <br/>
            <span>{i18next.t("confirmNotice")}</span>
            <br />
            <DefaultButton
              style={{ backgroundColor: "#0966c9", fontWeight: 'bold', color: "white", borderRadius: "10px" }}
              buttonType={ButtonType.hero}
              onClick={() => this.startProcess(true)}
            >
              {i18next.t("confirm")}{" "}
            </DefaultButton>
          </React.Fragment>
        ) : null }
      </div>
    );
  }
}
