/* eslint-disable prettier/prettier */
import * as React from "react";

export interface HeaderProps {
  title: string;
  logo: string;
  firstMessage: string;
  secondMessage: string;
}

export default class Header extends React.Component<HeaderProps> {
  render() {
    const { title, logo, firstMessage, secondMessage } = this.props;

    return (
      <section className='ms-welcome__header ms-bgColor-neutralLighter ms-u-fadeIn500'>
          <img width='90' height='90' src={logo} alt={title} title={title} />
          <br/>
          <h3 className='ms-fontWeight-light ms-fontColor-neutralPrimary'>{firstMessage}</h3>
          <h3 className='ms-fontWeight-light ms-fontColor-neutralPrimary'>{secondMessage}</h3>
      </section>
    );
  }
}
